import { useCallback } from 'react';
// utils
import { AxiosResponse } from 'axios';
import _axios, { _endpoints } from 'src/utils/_axios';
// types
import { useAuthContext } from 'src/auth/hooks';
import { ISiteItem } from 'src/types/site';

// ----------------------------------------------------------------------

/**
 * 获取 Site 列表数据
 */
export const useGetSiteListCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    ({
      page_no,
      input_row, // utc,
    }: {
      page_no?: number;
      input_row?: number;
      // utc: string;
    }) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error('User is not logged in'));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          page_no: page_no ?? 1,
          input_row: input_row ?? 10,
          utc: auth.user.user_utc,
        };

        _axios
          .get(_endpoints.site.list, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user]
  );
};

// ----------------------------------------------------------------------

/**
 * 获取 Site 列表数据
 */
export const useGetSiteInfoCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    ({
      search_site_id,
      page_id, // utc,
    }: {
      search_site_id: number;
      page_id: number;
      // utc: string;
    }) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error('User is not logged in'));
          return;
        }

        const params = {
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          search_site_id,
          page_id,
          utc: auth.user.user_utc,
        };

        _axios
          .get(_endpoints.site.details, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user]
  );
};

// ----------------------------------------------------------------------

/**
 * 创建组件
 */
export const useCreateSiteCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    (params: Partial<ISiteItem>): Promise<AxiosResponse> => {
      if (!auth.user) return Promise.reject(new Error('User is not logged in'));

      const _params = {
        client_id: auth.user.client_id as string,
        email: auth.user.email as string,
        verify_key: auth.user.verify_key as string,
        // gadget_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        name_en: params?.name_en ?? '',
        name_local: params?.name_local ?? '',
        site_prefix: params?.site_prefix ?? '',
        address1_en: params?.address1_en ?? '',
        address2_en: params?.address2_en ?? '',
        address1_local: params?.address1_local ?? '',
        address2_local: params?.address2_local ?? '',
        address3_local: params?.address3_local ?? '',
        city: params?.city ?? '',
        province: params?.province ?? '',
        country: params?.country ?? '',
        company_No: params?.company_No ?? '',
        zip: params?.zip ?? '',
        phone1: params?.phone1 ?? '',
        phone2: params?.phone2 ?? '',
        fax: params?.fax ?? '',
        site_email: params?.site_email ?? '',
        branch_no: params?.branch_no ?? '',
        site_Type: params?.site_Type ?? '',
        site_Status_ID: params?.site_Status_ID ?? '',
        time_zone: params?.time_zone ?? 0,
        geo_Code_ID: params?.geo_Code_ID ?? '',
        axAuth: params?.axAuth ?? '',
        start_date: params?.start_date ?? '',
        end_date: params?.end_date ?? '',
        isActive: params?.isActive ?? true,
        // create_date: new Date().toISOString(),
        // updated_date: new Date().toISOString(),
      } as unknown as ISiteItem;

      return (
        _axios
          // .post(_endpoints.dashboard.update, params)
          .post(`${_endpoints.site.create}?verify_key=${auth.user.verify_key}`, _params)
      );
    },
    [auth.user]
  );
};

// ----------------------------------------------------------------------

/**
 * 更新组件
 */
export const useUpdateSiteCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    (params: Partial<ISiteItem>): Promise<AxiosResponse> => {
      if (!auth.user) return Promise.reject(new Error('User is not logged in'));

      const _params = {
        site_id: params?.site_id ?? '',
        client_id: auth.user.client_id as string,
        email: auth.user.email as string,
        verify_key: auth.user.verify_key as string,
        // gadget_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        name_en: params?.name_en ?? '',
        name_local: params?.name_local ?? '',
        site_prefix: params?.site_prefix ?? '',
        address1_en: params?.address1_en ?? '',
        address2_en: params?.address2_en ?? '',
        address1_local: params?.address1_local ?? '',
        address2_local: params?.address2_local ?? '',
        address3_local: params?.address3_local ?? '',
        city: params?.city ?? '',
        province: params?.province ?? '',
        country: params?.country ?? '',
        company_No: params?.company_No ?? '',
        zip: params?.zip ?? '',
        phone1: params?.phone1 ?? '',
        phone2: params?.phone2 ?? '',
        fax: params?.fax ?? '',
        site_email: params?.site_email ?? '',
        branch_no: params?.branch_no ?? '',
        site_Type: params?.site_Type ?? '',
        site_Status_ID: params?.site_Status_ID ?? '',
        time_zone: params?.time_zone ?? 0,
        geo_Code_ID: params?.geo_Code_ID ?? '',
        axAuth: params?.axAuth ?? '',
        isActive: params?.isActive ?? true,
        start_date: params?.start_date ?? '',
        end_date: params?.end_date ?? '',
        // create_date: new Date().toISOString(),
        // updated_date: new Date().toISOString(),
      } as unknown as ISiteItem;

      return (
        _axios
          // .post(_endpoints.dashboard.update, params)
          .post(`${_endpoints.site.update}?verify_key=${auth.user.verify_key}`, _params)
      );
    },
    [auth.user]
  );
};
