import { useCallback } from 'react';
// utils
import { AxiosResponse } from 'axios';
import _axios, { _endpoints } from 'src/utils/_axios';
// types
import { useAuthContext } from 'src/auth/hooks';
import { IZoneItem } from 'src/types/zone';

// ----------------------------------------------------------------------

/**
 * 获取 Zone 列表数据
 */
export const useGetZoneListCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    ({
      site_id,
      site_area_ids,
      page_no,
      input_row, // utc,
    }: {
      site_id?: string;
      site_area_ids?: string[];
      page_no?: number;
      input_row?: number;
      // utc: string;
    }) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error('User is not logged in'));
          return;
        }
        console.log(auth.user);
        const params = {
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          site_id: site_id || (auth.user.site_id as string),
          // client_id: auth.user.client_id as string, // ignore client_id 2024年3月14日
          page_no: page_no ?? 1,
          input_row: input_row ?? 10,
          // utc: auth.user.user_utc,
        };

        _axios
          .get(_endpoints.zone.list, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user]
  );
};

// ----------------------------------------------------------------------

/**
 * 获取 Zone 列表数据
 */
export const useGetZoneInfoCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    ({
      search_site_id,
      search_zone_id,
      page_id, // utc,
    }: {
      search_site_id: number;
      search_zone_id: number;
      page_id: number;
      // utc: string;
    }) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error('User is not logged in'));
          return;
        }

        const params = {
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          client_id: auth.user.client_id as string,
          search_site_id,
          search_zone_id,
          page_id,
          utc: auth.user.user_utc,
        };

        _axios
          .get(_endpoints.zone.details, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user]
  );
};

// ----------------------------------------------------------------------

/**
 * 创建组件
 */
export const useCreateZoneCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    (params: Partial<IZoneItem>): Promise<AxiosResponse> => {
      if (!auth.user) return Promise.reject(new Error('User is not logged in'));

      const _params = {
        client_id: auth.user.client_id as string,
        email: auth.user.email as string,
        verify_key: auth.user.verify_key as string,
        // gadget_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        site_id: params?.site_id ?? '',
        area_id: params?.area_id ?? '',
        floor_id: params?.floor_id ?? '',
        zone_id: params?.zone_id ?? '',
        name_en: params?.name_en ?? '',
        name_local: params?.name_local ?? '',
        description_en: params?.description_en ?? '',
        description_local: params?.description_local ?? '',
        remark: params?.remark ?? '',
        is_testing: params?.is_testing ?? false,
        is_active: params?.is_active ?? true,
        polygon_json: params?.polygon_json ?? '',
      } as unknown as IZoneItem;

      return (
        _axios
          // .post(_endpoints.dashboard.update, params)
          .post(`${_endpoints.zone.create}?verify_key=${auth.user.verify_key}`, _params)
      );
    },
    [auth.user]
  );
};

// ----------------------------------------------------------------------

/**
 * 更新组件
 */
export const useUpdateZoneCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    (params: Partial<IZoneItem>): Promise<AxiosResponse> => {
      if (!auth.user) return Promise.reject(new Error('User is not logged in'));

      const _params = {
        client_id: auth.user.client_id as string,
        email: auth.user.email as string,
        verify_key: auth.user.verify_key as string,
        // gadget_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        site_id: params?.site_id ?? '',
        area_id: params?.area_id ?? '',
        floor_id: params?.floor_id ?? '',
        zone_id: params?.zone_id ?? '',
        name_en: params?.name_en ?? '',
        name_local: params?.name_local ?? '',
        description_en: params?.description_en ?? '',
        description_local: params?.description_local ?? '',
        remark: params?.remark ?? '',
        is_testing: params?.is_testing ?? false,
        is_active: params?.is_active ?? true,
        polygon_json: params?.polygon_json ?? '',
      } as unknown as IZoneItem;

      return (
        _axios
          // .post(_endpoints.dashboard.update, params)
          .post(`${_endpoints.zone.update}?verify_key=${auth.user.verify_key}`, _params)
      );
    },
    [auth.user]
  );
};
