import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';
// types
import { IFloorPlanItem } from 'src/types/floor-plan';

// ----------------------------------------------------------------------

export function useGetFloorPlans() {
  const URL = endpoints.floorPlan.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      floorPlans: (data?.floorplans as IFloorPlanItem[]) || [],
      floorPlansLoading: isLoading,
      floorPlansError: error,
      floorPlansValidating: isValidating,
      floorPlansEmpty: !isLoading && !data?.floorplans.length,
    }),
    [data?.floorplans, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetProduct(floorPlanId: string) {
  const URL = floorPlanId ? [endpoints.floorPlan.details, { params: { floorPlanId } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      floorPlan: data?.floorPlan as IFloorPlanItem,
      floorPlanLoading: isLoading,
      floorPlanError: error,
      floorPlanValidating: isValidating,
    }),
    [data?.floorPlan, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useSearchProducts(query: string) {
  const URL = query ? [endpoints.floorPlan.search, { params: { query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: (data?.floorplans as IFloorPlanItem[]) || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.floorplans.length,
    }),
    [data?.floorplans, error, isLoading, isValidating]
  );

  return memoizedValue;
}
