export const STORAGE_TOKEN = 'accessToken';
export const STORAGE_REFRESH_TOKEN = 'refreshToken';
export const ENCRYPTION_TYPE = [
  {
    label: 'None',
    value: '0',
  },
  {
    label: 'SSL',
    value: '1',
  },
  {
    label: 'TLS',
    value: '2',
  },
];
